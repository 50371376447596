import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useGetNutrientAnalysisMenuContributionsQuery } from "../../../../store/apis/MenuApis";
import { Box } from "@mui/material";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import BaseContent from "components/shared/baseContent/BaseContent";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";

export default function NutrientAnalysisMenuResults() {
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get("menuId");
  const [rowData, setRowData] = useState([]);
  const facilityId = useSelector(selectFacilityId);

  const {
    data: NutrientAnalysisMenuData,
    isFetching: NutrientAnalysisMenuLoading,
    isSuccess,
  } = useGetNutrientAnalysisMenuContributionsQuery(
    { menuId: menuId, facilityId: facilityId },
    {
      refetchOnMountOrArgChange: true,
      skip: !menuId, // Skip the initial request
    }
  );

  useEffect(() => {
    if (NutrientAnalysisMenuData?.length > 0) {
      setRowData(NutrientAnalysisMenuData);
    }
  }, [isSuccess]);
  console.log(rowData);
  const NutrientAnalysisMenuColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 30,
      rowSpanValueGetter: () => null,
    },
    {
      field: "week",
      headerName: "Week",
      flex: 0,
      headerAlign: "center",
      align: "left",
      minWidth: 5,
      rowSpanValueGetter: () => null,
    },
    {
      field: "kCalQuantity",
      headerName: "Calories",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "kCalWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "kCalRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "proteinQuantity",
      headerName: "Protein",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "proteinWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "proteinRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "carbohydrateQuantity",
      headerName: "Carbs",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "carbohydrateWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "carbohydrateRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "fatQuantity",
      headerName: "Fat",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "fatWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "fatRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "cholesterolQuantity",
      headerName: "Cholesterol",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "cholesterolWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "cholesterolRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "fiberQuantity",
      headerName: "Fiber",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "fiberWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "fiberRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "calciumQuantity",
      headerName: "Calcium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "calciumWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "calciumRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "ironQuantity",
      headerName: "Iron",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "ironWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "ironRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "potassiumQuantity",
      headerName: "Potassium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "potassiumWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "potassiumRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminAQuantity",
      headerName: "Vit A",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminAWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminARange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminCQuantity",
      headerName: "Vit C",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminCWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminCRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminB6Quantity",
      headerName: "Vit B6",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminB6WithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "vitaminB6Range",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "folateQuantity",
      headerName: "Folacin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "folateWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "folateRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "thiamineQuantity",
      headerName: "Thiamin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "thiamineWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "thiamineRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "niacinQuantity",
      headerName: "Niacin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "niacinWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "niacinRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "sodiumQuantity",
      headerName: "Sodium",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "sodiumWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "sodiumRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "riboflavinQuantity",
      headerName: "Riboflavin",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "riboflavinWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "riboflavinRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "phosphorusQuantity",
      headerName: "Phosphorus",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "phosphorusWithinRange",
      headerName: "In Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "phosphorusRange",
      headerName: "Range",
      headerAlign: "center",
      align: "center",
      flex: 0,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
  ];

  return (
    <>
      <BaseContent
        headerText={"Nutrient Analysis Calculations"}
        disableHeight={true}
      >
        <Box padding={1}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              overflow: "auto",
              flexDirection: "column",
            }}
          >
            <BaseDataGrid
              rows={NutrientAnalysisMenuData || []}
              columns={CreateBaseColumns(NutrientAnalysisMenuColumns)}
              unstable_rowSpanning={true}
              loading={NutrientAnalysisMenuLoading}
              getRowHeight={() => "auto"}
              height={"100%"}
              width={"100%"}
              EmptyRowMessage={"No Calculations found"}
              autoHeight={true}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import {
  defaultRecipeFields,
  recipeCheckboxes,
  recipeFields,
  recipeFieldsCol2,
} from "./forms.constants";
import { FormProvider, useForm } from "react-hook-form";
import {
  useNavigate,
  useSearchParams,
  useBlocker,
  useLocation,
} from "react-router-dom";
// import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { BaseButton } from "components/shared/BaseButton";
import { WarningText } from "components/shared/WarningText";
import {
  useAddRecipeMutation,
  useGetRecipeByIdUserFacilityQuery,
  useUpdateRecipeMutation,
} from "store/apis/RecipeAPIs";
import { useGetRecipeCategorySelectBoxQuery } from "store/apis/RecipeCategoriesApis";
import { useSelector } from "react-redux";
import { Roles } from "components/shared/constants";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import BaseInput from "components/shared/baseForm/BaseInput";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { EditSkeleton } from "components/admin/admin.overlays";
import "./AddRecipe.css";
import BaseContent from "components/shared/baseContent/BaseContent";
import DeleteButton from "components/shared/DeleteButton";
import BlockerModal from "components/shared/blockerModal/BlockerModal";
import useMsalAccount from "utils/useMsalAccount";
import uploadIcon from "../../../../assets/imgs/upload_icon.svg";
import deleteIcon from "../../../../assets/imgs/delete_icon.svg";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function AddRecipe() {
  const currentLocation = useLocation();

  const previousUrl = currentLocation?.state?.previousLink;
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const Message = searchParams.get("Message");
  const fileInputRef = useRef(null);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState(null);
  const [blockerModal, setBlockerModal] = useState(false);
  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [deleteImg, setDeleteImg] = useState(false);
  const [corporateOnly, setCorporateOnly] = useState(false);

  // data from store
  const facilityId = useSelector(selectFacilityId);
  const { userId, roles: userRoles } = useMsalAccount();

  const navigate = useNavigate();

  const isEdit = !!recipeId;

  // useForm hook here

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: defaultRecipeFields,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    // if (Message == "CopyRecipe") setHasChange(isDirty);
    setHasChange(isDirty);
  }, [isDirty]);

  const canDeleteRecipe = (isHTIRecipe) => {
    const userAllowedWithRoles = [Roles.Admin, Roles.Menu_Team];
    const isRoleAllowedToDelete = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );

    const canDelete =
      (isRoleAllowedToDelete && isHTIRecipe) ||
      (!isRoleAllowedToDelete && !isHTIRecipe);

    return canDelete;
  };

  // for disabling the category field for non-admin users (and setting to house recipes)
  const isAdmin = () => {
    const userAllowedWithRoles = [Roles.Admin, Roles.Menu_Team];
    const isInRole = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );
    return isInRole;
  };

  const isCorporateAdminRole = () => {
    const userAllowedWithRoles = [Roles.Corporate_Group_Admin];
    const isInRole = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );
    return isInRole;
  };

  // APIs Calls here

  const {
    data: recipeCategoryList,
    isLoading: recipeCategoryLoading,
    isSuccess: recipeCategorySuccess,
    refetch: refetchRecipeCategory,
  } = useGetRecipeCategorySelectBoxQuery(
    {
      facilityId: facilityId,
      filterToHTI: false,
      filterToCorporateGroup: corporateOnly,
    },
    {
      skip: !facilityId,
    }
  );

  const {
    data: currentRecipe,
    isLoading: currentRecipeLoading,
    isSuccess: currentRecipeSuccess,
  } = useGetRecipeByIdUserFacilityQuery(
    {
      recipeId: recipeId,
      userId: userId,
      facilityId: facilityId,
    },
    { skip: !recipeId || !userId || !facilityId }
  );

  const [
    addRecipe,
    {
      data: newRecipe,
      isLoading: addRecipeLoading,
      isSuccess: addRecipeSuccess,
    },
  ] = useAddRecipeMutation();

  const [
    updateRecipe,
    { isLoading: updateRecipeLoading, isSuccess: updateRecipeSuccess },
  ] = useUpdateRecipeMutation();

  const isSubmitting = addRecipeLoading || updateRecipeLoading;
  const isSuccess = addRecipeSuccess || updateRecipeSuccess;

  const handleImageUpload = () => {
    setHasChange(true);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    setHasChange(true);
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (isSuccess)
    navigate(
      `/plateFul/Recipes/recipedetail?recipeId=${
        recipeId ? recipeId : newRecipe?.data?.id
      }`,
      { state: { canEdit: true, ...currentLocation.state } }
    );

  useEffect(() => {
    if (currentRecipeSuccess) {
      reset(currentRecipe);
      setPreview(currentRecipe?.imageUrl);
    }
  }, [currentRecipeSuccess]);

  useEffect(() => {
    if (recipeCategorySuccess && !recipeCategoryLoading) {

      if (!isAdmin() && !isCorporateAdminRole()) {
        // set to house recipe category if a new recipe is being created by non-admin users
        // either way, disable the category field
        if (!isEdit) {
          setValue("categoryId", 29);
        }
        setCategoryDisabled(true);
      } else if (!isAdmin() && isCorporateAdminRole()) {
        setCorporateOnly(true);
        refetchRecipeCategory();
      }
    }
  }, [recipeCategoryLoading, recipeCategorySuccess]);

  // UnSaved Changes Feature here
  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };
  // onSubmit functionality here
  const onSubmit = (data) => {
    setHasChange(false);
    data.textureId = data.textureId || "";
    data.panSizeId = data.panSizeId || "";
    data.menuCategoryId = data.menuCategoryId || "";

    const formData = new FormData();
    if (file) formData.append("image", file);

    for (let property in data) {
      if (data[property] !== null) {
        formData.append(property, data[property]);
      }
    }

    if (!isEdit) {
      addRecipe({ userId, facilityId, data: formData });
    } else if (isEdit) {
      formData.append("removeImage", deleteImg);
      updateRecipe({
        userId: userId,
        facilityId: facilityId,
        data: formData,
      });
    }
  };

  const handleDeleteImage = () => {
    setHasChange(true);

    setPreview("");
    setDeleteImg(true);
  };

  return currentRecipeLoading ? (
    <EditSkeleton />
  ) : (
    <>
      {blockerModal ? (
        <BlockerModal
          text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}

      <BaseContent
        headerText={
          currentRecipe?.name ? `Recipe - ${currentRecipe?.name}` : ""
        }
        backText="Back to Recipe"
        backLink={previousUrl ? previousUrl : -1}
        disableHeight={true}
      >
        {Message == "CopyRecipe" && (
          <>
            <WarningText
              text={`You are now working with the copied version of the recipe. Change the Name of the recipe to make it unique.`}
              width="97%"
              color="primary"
              sx={{
                marginY: "13px !important",
              }}
            />
            <WarningText
              text={`Don't forget to update the Dislikes for this copied menu.`}
              width="97%"
              color="warning"
              sx={{
                marginY: "13px !important",
              }}
            />
          </>
        )}

        <Box
          sx={{
            height: { md: "78vh", xl: "82vh" },
          }}
        >
          <FormProvider {...methods}>
            <Box
              sx={{
                display: "flex",
                marginLeft: "10px",
                marginTop: 1,
                gap: 2,
                overflow: "auto",
                height: { md: "60vh", lg: "70vh", xl: "73vh" },
              }}
            >
              <Box
                sx={{
                  width: "51.5%",
                  marginBottom: 1,
                  mt: 1,
                }}
              >
                {isEdit ? (
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {currentRecipe?.name}
                    </Typography>
                    <Typography variant="p">
                      Recipe Number : {currentRecipe?.id}
                    </Typography>
                  </Box>
                ) : null}
                <Grid
                  container
                  spacing={1}
                  sx={{
                    bgcolor: "#ECECEC",
                    borderRadius: 1,
                    paddingX: "2px",
                    paddingY: "5px",
                    marginY: "10px",
                    marginLeft: "1px",
                  }}
                >
                  <GeneratedForm
                    list={recipeCheckboxes}
                    control={control}
                    errors={errors}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item size={{ lg: 12 }}>
                    <BaseInput
                      name="name"
                      id="name"
                      label="Name"
                      validationProps={{
                        required: REQUIRED_ERROR("name"),
                        maxLength: {
                          value: 50,
                          message: "Max Character Length is 50",
                        },
                      }}
                      control={control}
                      errors={errors}
                    />
                  </Grid>

                  <Grid item size={{ lg: 4 }}>
                    <BaseSelect
                      name="categoryId"
                      id="categoryId"
                      label="Recipe Category"
                      options={recipeCategoryList || []}
                      validationProps={{
                        required: REQUIRED_ERROR("Recipe Category"),
                      }}
                      loading={recipeCategoryLoading}
                      control={control}
                      errors={errors}
                      disabled={categoryDisabled}
                    />
                  </Grid>
                  <GeneratedForm
                    oldGrid={false}
                    list={recipeFields}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              </Box>
              <Box
                sx={{
                  width: "47.5%",
                  marginBottom: 1,
                  mt: 1,
                }}
              >
                <Grid container spacing={1}>
                  <GeneratedForm
                    oldGrid={false}
                    list={recipeFieldsCol2}
                    control={control}
                    errors={errors}
                  />
                </Grid>

                <Typography
                  sx={{
                    fontWeight: "bold",
                    marginY: 0.5,
                  }}
                >
                  Recipe Photo
                </Typography>
                <Box
                  sx={{
                    height: "24vh",
                    border: "1px solid black",
                    borderRadius: 2,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {preview && (
                    <img
                      src={preview}
                      alt="Image preview"
                      style={{ width: "auto", height: "100%" }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.5rem",
                    gap: "0.5rem",
                  }}
                >
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".jpg, .jpeg, .png, .webp, .gif, .tif, .tiff, .bmp"
                    onChange={handleFileChange}
                    hidden
                  />
                  <Box
                    component="img"
                    alt="Default Profile Image"
                    onClick={handleImageUpload}
                    sx={{
                      border: "1px solid white",
                      padding: "0.3rem 0.4rem",
                      borderRadius: "6px",
                      background: "var(--tealColor)",
                      cursor: "pointer",
                    }}
                    src={uploadIcon}
                  ></Box>
                  <Box
                    component="img"
                    size="small"
                    onClick={handleDeleteImage}
                    sx={{
                      border: "1px solid white",
                      padding: "0.3rem 0.5rem",
                      borderRadius: "6px",
                      background: "red",
                      cursor: "pointer",
                    }}
                    src={deleteIcon}
                  ></Box>
                </Box>
              </Box>
            </Box>
          </FormProvider>
          <FlexBox mt={3} pb={1} justifyContent={"start"} position={"relative"}>
            {isEdit ? (
              canDeleteRecipe(currentRecipe?.htirecipe) ? (
                <Box
                  sx={{
                    width: "42%",
                  }}
                >
                  <DeleteButton
                    index={currentRecipe?.id}
                    entityName={currentRecipe?.name}
                    text="Archive Recipe"
                    variant="contained"
                    apiPath="deletePlatefulRecipes"
                    To={"/plateFul"}
                  />
                </Box>
              ) : null
            ) : null}
            <Box display="flex" gap={1}>
              <BaseButton
                fullWidth
                text="Cancel"
                onClick={() => navigate(-1)}
                colors="white"
              />
              <BaseButton
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Save" : "Create"}
              />
            </Box>
          </FlexBox>
        </Box>
      </BaseContent>
    </>
  );
}

// MUI Imports
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
  Grid2,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { Cached } from "@mui/icons-material";

// React Hook Imports
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBlocker, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useCustomDrop } from "../../../shared/customeHooks/useCustomDrop";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

// Query And Mutation Imports
import {
  useGetFetchWeekListQuery,
  useGetRecipesListQuery,
  useSaveMenuDataMutation,
} from "../../../../store/apis/MenuManagerApi";
import { useGetMenuByIdQuery } from "../../../../store/apis/MenuApis";

// custom Component
import GeneratedForm from "../../../shared/generatedForm/GeneratedForm";
import { menuPlannerFields } from "./form.constant";
import { RecipeCard } from "./shared/RecipeCard";
import { RecipeSymbols } from "./shared/RecipeSymbols";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import { BaseButton } from "../../../shared/BaseButton";
import { RecipeBoard } from "./shared/RecipeBoard";
import { EditSkeleton } from "../../../admin/admin.overlays";
import BaseContent from "../../../shared/baseContent/BaseContent";
import BlockerModal from "components/shared/blockerModal/BlockerModal";
import { Roles } from "components/shared/constants";

// Lodash Imports
import debounce from "lodash/debounce";
// Redux Slice Section
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";
import _, { isEmpty, isNull } from "lodash";
import useMsalAccount from "utils/useMsalAccount";
import FlexBox from "components/shared/styledComponents/FlexBox";
import {
  selectVendorId,
  selectVendorName,
} from "../../../../store/slices/userSlice/UserSlice";
import { useRecalcVendorPricingMutation } from "../../../../store/apis/VendorApis";

export const MenuPlanner = () => {
  // REACT_APP_SHOW_HEP is a feature flag in .env that controls whether to show HEP functionality
  // Set to 'true' to enable HEP features, 'false' to disable
  // eslint-disable-next-line no-undef
  const showHep = process.env.REACT_APP_SHOW_HEP === "true";

  // local State Declaration
  const { accountFromDB } = useMsalAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const loadingRef = useRef(null);
  const [data, setData] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [blockerModal, setBlockerModal] = useState(false);
  const [weekNum, setWeekNum] = useState(null);
  const [selectChange, setSelectChange] = useState(false);
  const [searchParams] = useSearchParams();
  const [debouncedValue, setDebouncedValue] = useState();
  const [menuPlannerData, setMenuPlannerData] = useState([]);
  const facilityId = useSelector(selectFacilityId);
  const vendorId = useSelector(selectVendorId);
  const vendorName = useSelector(selectVendorName);
  const menuId = searchParams.get("menuId");
  const [searchKey, setSearchKey] = useState();
  const isChangeTrue = useSelector((state) => state.changeTrue.isChangeTrue);
  const dispatch = useDispatch();
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [queries, setQueries] = useState([
    {
      name: "category",
      query: {
        facilityId: facilityId,
        filterToHTI: true,
        filterToCorporateGroup: false,
      },
      skip: !facilityId,
    },
  ]);
  const [recalcPricing] = useRecalcVendorPricingMutation();

  const onSubmit = () => {
    recalcPricing({ vendorId, menuId });
  };

  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      isChangeTrue && currentLocation.pathname !== nextLocation.pathname,
    [isChangeTrue]
  );

  const blocker = useBlocker(shouldBlock);
  const id = menuId;
  // UseForm Declaration
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructuring Method
  const { watch, setValue, handleSubmit } = methods;

  // useForm Input Field Veriable Declaration
  const searchTerm = watch("name") || "";
  const categoryId = watch("category") || "";
  const weekNumber = watch("weekNumber") || "";
  const tagIds = watch("tags") || [];
  const isDescription = watch("isDescription") || false;
  const isCost = watch("isCost") || false;
  const corporateGroup = -1; // watch("corporateGroup") || -1;
  const htiCategory = watch("htiCategory") || false;

  // redux Roles
  const { roles: userRoles } = useMsalAccount();
  const isRoleAllowed = userRoles.includes(Roles.Admin);

  // UseEffect Section
  useEffect(() => {
    debounceHandler(searchTerm);
  }, [searchTerm]);
  const debounceHandler = useMemo(
    () =>
      debounce((searchTerm) => {
        setDebouncedValue(searchTerm);
      }, 500),
    []
  );

  // Query Section
  const { data: recipeData, isFetching: loading } = useGetRecipesListQuery(
    {
      searchTerm: debouncedValue,
      categoryId,
      tagIds,
      corporateGroup,
      htiCategory,
      skip: page * 100, // Update the skip value based on the page
      take: 100,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !debouncedValue && !categoryId && !tagIds, // Skip the initial request
    }
  );

  const { data: menuData } = useGetMenuByIdQuery(id);
  const {
    data: weekListData,
    isFetching: weeksLoading,
    error: weekErrorData,
  } = useGetFetchWeekListQuery({
    menuId,
  });

  // UseEffect Section

  useEffect(() => {
    setPage(0);
    setData([]);
  }, [categoryId, tagIds, htiCategory, corporateGroup, debouncedValue]);

  useEffect(() => {
    if (!loading) {
      // Added loading check to not change the page when the current data is loading.
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setPage((prevPage) => {
              return prevPage + 1;
            });
          }
        });
      }, options);

      if (loadingRef.current) {
        observer.observe(loadingRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [loadingRef.current, data.length !== 0, loading]); // Add loadingRef.current to the dependency array

  // When new data is fetched
  useEffect(() => {
    if (recipeData && recipeData?.length !== 0) {
      setIsCompleted(false);
      setData((prevData) => {
        // Create a new array that only includes the items from recipeData
        // that don't have an id that's already in prevData
        const newRecipeData = recipeData.filter(
          (recipe) =>
            !prevData.some((data) => data.recipeId === recipe.recipeId)
        );

        // Return the new data array
        return [...prevData, ...newRecipeData];
      });
    } else {
      setIsCompleted(true);
    }
  }, [recipeData]);

  const [saveMenu, { isLoading: saveMenuLoading, isSuccess: saveMenuSuccess }] =
    useSaveMenuDataMutation({
      refetchOnMountOrArgChange: true,
    });
  useEffect(() => {
    if (saveMenuSuccess) {
      enqueueSnackbar(`Saved Successfully`, {
        variant: "success",
      });
    }
  }, [saveMenuSuccess]);
  const handleSave = (event) => {
    event.preventDefault();
    !isNull(menuPlannerData?.resultSet)
      ? saveMenu(menuPlannerData?.resultSet)
      : null;
    dispatch(setIsChangeTrue(false));
  };

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (isChangeTrue) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [isChangeTrue]);

  useEffect(() => {
    if (!isChangeTrue) {
      setWeekNum(weekNumber);
    }
  }, [weekNumber, isChangeTrue]);
  useEffect(() => {
    if (isChangeTrue && weekNumber !== weekNum) {
      setBlockerModal(true);
      setSelectChange(true);
    }
  }, [weekNumber]);

  useEffect(() => {
    setValue("weekNumber", weekListData ? weekListData[0]?.id : null);
    setWeekNum(weekListData ? weekListData[0]?.id : null);
  }, [weekListData]);

  // Function Will Swap Lunch with Supper
  const swapAllLunchAndSupper = (event) => {
    event.preventDefault();
    dispatch(setIsChangeTrue(true));
    const menuPlanner = structuredClone(menuPlannerData);
    const lunchData = menuPlannerData?.resultSet
      .flatMap((data, index) => ({ data, index }))
      .filter((item) => item.data.mealName === "Lunch")
      .map((filteredItem) => {
        const { data, index } = filteredItem; // Extract the data and index
        // Process each filtered data object here if needed
        return { ...data, originalIndex: index }; // Add the original index to the object
      });
    const supperData = menuPlannerData?.resultSet
      .flatMap((data, index) => ({ data, index }))
      .filter((item) => item.data.mealName === "Dinner")
      .map((filteredItem) => {
        const { data, index } = filteredItem; // Extract the data and index
        // Process each filtered data object here if needed
        return { ...data, originalIndex: index }; // Add the original index to the object
      });
    menuPlannerData.resultSet[0].columnHeadings.map((data, index) => {
      let lunchRecipes = lunchData[0]?.days[index].recipes;
      let supperRecipes = supperData[0]?.days[index].recipes;
      menuPlanner.resultSet[lunchData[0]?.originalIndex].days[index].recipes =
        supperRecipes;
      menuPlanner.resultSet[supperData[0]?.originalIndex].days[index].recipes =
        lunchRecipes;
    });
    setMenuPlannerData(menuPlanner);
  };

  const deleteDropCard = (items) => {
    //filter out when we pick up and drop search item back into list without adding it to menu first
    items = items.filter((item) => item.rows >= 0 && item.column >= 0);

    const menuPlanner = structuredClone(menuPlannerData);
    // just need one copy of each meal with selections they have CTRL-clicked
    const mealsToCheck = _.uniqWith(
      items,
      (itemA, itemB) =>
        itemA.rows === itemB.rows && itemA.column === itemB.column
    );

    let previousRecipeDays = mealsToCheck?.map((day) => {
      return {
        recipes:
          menuPlannerData?.resultSet[day?.rows]?.days[day?.column]?.recipes,
        row: day?.rows,
        column: day?.column,
      };
    });

    previousRecipeDays = previousRecipeDays.map((recipeDayList) => {
      return {
        recipes: recipeDayList.recipes?.filter(
          (recipe) =>
            !items.some(
              (item) =>
                item.rows === recipeDayList.row &&
                item.column === recipeDayList.column &&
                item.finalData?.recipeId === recipe.recipeId
            )
        ),
        row: recipeDayList.row,
        column: recipeDayList.column,
      };
    });

    previousRecipeDays.forEach((recipeDayList) => {
      menuPlanner.resultSet[recipeDayList.row].days[
        recipeDayList.column
      ].recipes = recipeDayList.recipes;
    });

    setMenuPlannerData(menuPlanner);
    dispatch(setIsChangeTrue(true));
    setWeekNum(weekNumber);
    setSelectedRecipes([]); // reset selection array
  };

  const { isOver: isOverDelete, drop: deleteDrop } = useCustomDrop(
    ["card", "delete"],
    deleteDropCard
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  const handleOnLeave = () => {
    if (!selectChange) {
      blocker.proceed();
      setBlockerModal(false);
      dispatch(setIsChangeTrue(false));
    } else {
      setBlockerModal(false);
      setWeekNum(weekNumber);
      dispatch(setIsChangeTrue(false));
    }
  };
  const handleOnStay = () => {
    if (!selectChange) {
      blocker.reset();
      setBlockerModal(false);
    } else {
      setValue("weekNumber", weekNum);
      setBlockerModal(false);
    }
  };
  useEffect(() => {
    setQueries([
      ...queries.filter((x) => x.name != "category"),

      {
        name: "category",
        query: {
          facilityId: facilityId,
          filterToHTI: htiCategory,
          filterToCorporateGroup: false,
        },
      },
    ]);
  }, [htiCategory]);

  return (
    // BaseContent component
    <>
      {blockerModal ? (
        <BlockerModal
          text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}
      <Box sx={{ marginLeft: "10px" }}>
        <BaseContent
          backText={"Back to Menu List"}
          backLink={"/plateFul/Menus"}
          headerText={`Menu Planner:  ${
            menuData?.menuName ? menuData?.menuName : ""
          }`}
          subHeaderText={`(${vendorName})`}
          boxMargin={"5px 0px 0px 0px"}
          cardPadding={"0px"}
          cardMarginTop={"0px"}
          disableHeight={true}
          transparent={true}
          cardOverFlow={false}
        >
          <FormProvider {...methods}>
            <Box
              sx={{
                height: { md: "90.5vh", xl: "92vh" },
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "2px",
                  boxShadow: "var(--tealBoxShadow)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginX: "5px",
                    justifyContent: "space-between",
                    minWidth: { md: "50vw", xl: "55vw" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <BaseCheckBox
                        name="isDescription"
                        id="isDescription"
                        label="Show Description"
                        size="small"
                      />
                    </Box>
                    {accountFromDB?.showVendorPricing ? (
                      <Box>
                        <BaseCheckBox
                          name="isCost"
                          id="isCost"
                          label="Show Cost"
                          size="small"
                        />
                      </Box>
                    ) : null}
                    <Box>
                      {isCost ? (
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "2px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Average Daily Cost: $
                          {weekNumber !== ""
                            ? menuPlannerData?.averageDailyCost
                              ? menuPlannerData?.averageDailyCost.toFixed(2)
                              : "0.00"
                            : "0.00"}
                          <Cached
                            onClick={handleSubmit(onSubmit)}
                            sx={{
                              color: "var(--tealColor)",
                              transform: "rotate(90deg)",
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          />
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: { md: "20vw", lx: "30vw", margin: "0px 10px" },
                      }}
                    >
                      <BaseSelect
                        name="weekNumber"
                        id="weekNumber"
                        label="Week Number"
                        size="small"
                        loading={weeksLoading}
                        options={weekListData || []}
                      />
                    </Box>
                    <Box>
                      <Tooltip
                        arrow
                        title={`${menuData?.menuName}: Select a week. Then drag & drop the list items to move them around, or just click to select them.`}
                      >
                        <IconButton color="primary">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <FlexBox justifyContent={"end"}>
                  {showHep ? (
                    <BaseButton
                      text="HEP Calculations"
                      To={`/plateFul/hep/HepResults?menuId=${menuId}&week=${weekNumber}`}
                    />
                  ) : null}
                  {showHep ? (
                    <BaseButton
                      text="Nutrient Analysis Calculations"
                      To={`/plateFul/nutrientAnalysisMenu/NutrientAnalysisMenuResults?menuId=${menuId}`}
                    />
                  ) : null}
                  <BaseButton
                    text="Swap Lunch and Dinner"
                    startIcon="swap_calls"
                    onClick={swapAllLunchAndSupper}
                  />
                  <BaseButton
                    text="Save"
                    colors={"error"}
                    isSubmitting={saveMenuLoading}
                    onClick={handleSave}
                    disabled={watch("weekNumber") == undefined}
                  />
                </FlexBox>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: { md: "91%", xl: "93%" },
                  marginLeft: "3px",
                }}
              >
                <Box
                  sx={{
                    width: { md: "16vw", xl: "17vw" },
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      boxShadow: "var(--tealBoxShadow)",
                      width: "99%",
                      border: "1px solid #CCCCCC",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        margin: "10px 2px 5px",
                      }}
                    >
                      <Grid2 container spacing={0.5}>
                        <GeneratedForm
                          oldGrid={false}
                          marginY={"4px"}
                          list={menuPlannerFields}
                          queries={queries}
                        />
                      </Grid2>
                    </Box>
                    <Box
                      ref={deleteDrop}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: { md: "41vh", xl: "46vh" },
                        overflow: "auto",
                        opacity: isOverDelete ? 0.7 : 1,
                      }}
                    >
                      {loading && !data ? (
                        <EditSkeleton size={20} />
                      ) : data?.length > 0 ? (
                        <>
                          {data?.map((data, index) => (
                            <RecipeCard
                              isCost={isCost}
                              data={data}
                              isDescription={true}
                              keyIndex={index}
                              searchKey={searchKey}
                              setSearchKey={setSearchKey}
                              selectedRecipes={selectedRecipes}
                              setSelectedRecipes={setSelectedRecipes}
                              key={`${data.recipeId}-${data.menuRecipeId}`} // Use a unique identifier from your data
                              dbClickNav={true}
                              supportMultiSelect={false}
                            />
                          ))}
                        </>
                      ) : (
                        <Box
                          sx={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {!loading ? (
                            <Typography
                              component="h2"
                              sx={{
                                color: "var(--grayColor)",
                              }}
                            >
                              {!!searchTerm && isEmpty(recipeData)
                                ? "No Recipes found"
                                : "Search for Recipe"}
                            </Typography>
                          ) : (
                            <CircularProgress size={20} />
                          )}
                        </Box>
                      )}
                      {data?.length !== 0 && !isCompleted && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress ref={loadingRef} size={20} />
                        </Box>
                      )}
                      {/* Add this at the end of your list */}{" "}
                    </Box>
                  </Box>
                  {isRoleAllowed ? (
                    <Box
                      sx={{
                        padding: "3px",
                      }}
                    >
                      <BaseCheckBox
                        name={"htiCategory"}
                        id={"htiCategory"}
                        label={"HTI Categories/Recipes Only"}
                        size={"small"}
                        fontSize={"14px"}
                        defaultValue={isRoleAllowed}
                      />
                    </Box>
                  ) : null}
                  <RecipeSymbols />
                </Box>
                <Box
                  sx={{
                    width: { md: "84vw", xl: "83vw" },
                    border: "1px solid white",
                  }}
                >
                  <Box
                  // sx={{
                  //   height: { md: "75vh", xl: "81vh" },
                  // }}
                  >
                    {weekNum ? (
                      <RecipeBoard
                        weekNumber={weekNum}
                        menuId={menuId}
                        isDescription={isDescription}
                        isCost={isCost}
                        menuPlannerData={menuPlannerData}
                        setMenuPlannerData={setMenuPlannerData}
                        selectedRecipes={selectedRecipes}
                        setSelectedRecipes={setSelectedRecipes}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "84vw",
                          height: "75vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="h2"
                          sx={{
                            color: "var(--grayColor)",
                          }}
                        >
                          {!weekNum && !!weekErrorData
                            ? `No weeks configured for menu ${
                                menuData?.menuName ? menuData?.menuName : ""
                              }`
                            : `Select a Week Number`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </FormProvider>
        </BaseContent>
      </Box>
    </>
  );
};

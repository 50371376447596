import { Box, Card, ListItemIcon, Skeleton, Typography } from "@mui/material";
import React from "react";
import { BaseButton } from "../BaseButton";
import { useSelector } from "react-redux";
import { selectHeaderText } from "store/slices/appSlice/AppSlice";
import { DMStyles } from "styles/Styles";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "styles/theme";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseContent.md)
 */

const BaseContent = ({
  headerText,
  subHeaderText,
  backLink = -1,
  backText = "Back",
  backIcon = "arrow_back",
  children,
  transparent = false,
  disableBack = false,
  disableHeight = false,
  cardPadding = "8px",
  boxMargin,
  cardMarginTop = "10px",
  btnColor,
  cardOverFlow = true,
  isStateHeaderText = false,
  headerTextLoading = false,
  icon = true,
}) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const stateHeaderText = isStateHeaderText
    ? useSelector(selectHeaderText)
    : headerText;

  let styleObject = {
    padding: cardPadding,
    marginTop: cardMarginTop,
    backgroundColor: transparent ? "transparent" : "white",
  };

  if (!disableHeight) {
    styleObject["flexGrow"] = 1;
    styleObject["display"] = "flex";
    styleObject["height"] = "100%";
    styleObject["flexDirection"] = "column";
  }

  if (cardOverFlow) {
    styleObject["overflow"] = "auto";
  }

  if (!transparent) {
    styleObject["boxShadow"] = "none !important";
  }
  const backNavigation = () => navigate(-1);

  const linkName = pathname?.split("/")[1];
  return (
    <Box
      id="base-content"
      sx={{
        height: "100%",
        marginX: boxMargin || 1,
        marginY: boxMargin || 0,
        display: "flex",
        flexDirection: "column",
        marginTop: "-69px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          flexDirection: "row",
          minHeight: "76px",
          paddingY: "0.8rem",
          justifyContent: "flex-start",
          alignItems: "center",
          background:
            pathname === "/home" || linkName.length === 0
              ? "transparent"
              : theme.palette.green.greenGradient,
        }}
      >
        {!disableBack && (
          <BaseButton
            colors={btnColor}
            To={backLink ? backLink : undefined}
            state={state}
            zIndex={2}
            text={backText}
            marginLeft={"6px"}
            startIcon={
              icon && <span className="material-icons">{backIcon}</span>
            }
            onClick={!backLink ? backNavigation : undefined}
          />
        )}
        {headerTextLoading ? (
          <Skeleton
            sx={{
              marginLeft: disableBack ? "24px" : "8px",
            }}
            variant="text"
            width={200}
            height={"40px"}
          />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!(pathname === "/home" || linkName.length === 0) && (
              <GetIconName linkName={linkName} />
            )}
            <Typography
              variant="h1"
              component={"h1"}
              sx={{
                fontSize: DMStyles.HeadingFontSize,
                fontWeight: "900",
                width: !disableBack && !subHeaderText ? "100%" : null,
                marginLeft: disableBack ? "24px" : "8px",
                color: "white !important",
                // marginTop: "1.1rem",
                whiteSpace: "nowrap", // Prevents text wrapping
                zIndex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {stateHeaderText}
            </Typography>
            {subHeaderText ? (
              <Typography
                variant="h1"
                component={"h1"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "900",
                  fontStyle: "italic",
                  marginLeft: "8px",
                  marginTop: "4px",
                  zIndex: 1,
                }}
              >
                {subHeaderText}
              </Typography>
            ) : null}
          </Box>
        )}
      </Box>
      <Card
        id="base-content-card"
        sx={{
          ...styleObject,
        }}
      >
        {children}
      </Card>
    </Box>
  );
};

export default BaseContent;

function GetIconName({ linkName }) {
  let icon;
  if (!linkName) return null;
  switch (true) {
    case linkName.includes("admin"):
      icon = "admin_panel_settings";
      break;
    case linkName.includes("home"):    
      icon = "home";
      break;
    case linkName.includes("Consultant"):
      icon = "assignment";
      break;
    case linkName.includes("plateFul"):
      icon = "flatware";
      break;
    case linkName.includes("MealCard"):
      icon = "groups";
      break;
    case linkName.includes("TableSide"):
      icon = "table_restaurant";
      break;
    case linkName.includes("clientPortal"):
      icon = "computer";
      break;
    case linkName.includes("FAQ"):
      icon = "help";
      break;
    case linkName.includes("contact"):
      icon = "phone";
      break;
    case linkName.includes("chatAIConvo"):
      icon = "message";
      break;
    case linkName.includes("mycredentials"):    
      icon = "home";
      break;
    default:
      icon = null;
      break;
  }

  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        border: "3px solid white",
        borderRadius: "50%",
        width: "55px",
        height: "55px",
        backgroundColor: "transparent",
        marginLeft: "1rem",
      }}
    >
      <ListItemIcon>
        <span
          style={{
            color: "white",
            margin: "0 auto",
          }}
          className="material-icons"
        >
          {icon}
        </span>
      </ListItemIcon>
    </Box>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import {
  useAddRecipeDietMutation,
  useUpdateRecipeDietMutation,
  useGetAlternativeRecipeSearchListQuery,
  useGetRecipeDietListQuery,
  useGetFacilityDietsSelectBoxQuery,
} from "../../../../../../store/apis/PlateFulRecipeDietApis";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Typography, Button, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BaseSelect from "../../../../../shared/baseForm/BaseSelect";
import BaseAutoComplete from "../../../../../shared/baseForm/BaseAutoComplete";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../../store/slices/authSlice/AuthSlice";
import useMsalAccount from "utils/useMsalAccount";

export function AddRDiet() {
  const facilityId = useSelector(selectFacilityId);
  const { userId } = useMsalAccount();

  const [
    addRecipeDiet,
    { isSuccess: addSuccess, isError: addError, isLoading: addLoading },
  ] = useAddRecipeDietMutation();

  const [
    updateRecipeDiet,
    {
      isSuccess: updateSuccess,
      isError: updateError,
      isLoading: updateLoading,
    },
  ] = useUpdateRecipeDietMutation();

  const isSuccess = addSuccess || updateSuccess;
  const isError = addError || updateError;

  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");

  const location = useLocation();
  const recipeDietData = location.state?.data;
  const currentId = location.state?.data?.id;
  const isEdit = !!currentId;

  const [defaultRecipeType, setDefaultRecipeType] = useState([]);

  const { data: diets, isLoading: loading } = useGetFacilityDietsSelectBoxQuery(
    { search: "", facilityId },
    { skip: !facilityId }
  );

  const { data: recipeDiets } = useGetRecipeDietListQuery({
    recipeId: recipeId,
    facilityId: facilityId,
  });

  const recipeDietsIds = isEdit
    ? recipeDiets
        ?.map((item) => item.dietId)
        .filter((id) => id !== recipeDietData.dietId) || []
    : recipeDiets?.map((item) => item.dietId);

  // remove 'Regular' diet/texture from list.
  const filteredDiets = diets?.filter(
    (diet) =>
      Array.isArray(recipeDietsIds) &&
      !recipeDietsIds.includes(diet.id) &&
      diet.id !== 25 &&
      diet.id !== 19 &&
      diet.id !== 27 &&
      diet.id !== 9
  );

  useEffect(() => {
    if (location.state && recipeDietData) {
      reset(recipeDietData);
      setDefaultRecipeType([
        {
          id: recipeDietData.alternateRecipeId,
          label: recipeDietData.alternateRecipeName,
        },
      ]);
    }
  }, [recipeDietData]);

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    if (isEdit) {
      updateRecipeDiet({
        dietId: data.dietId,
        alternateRecipeId: data.alternateRecipeId,
        recipeId: parseInt(recipeId),
        id: currentId,
      });
    } else {
      addRecipeDiet({
        dietId: data.dietId,
        alternateRecipeId: data.alternateRecipeId,
        recipeId: parseInt(recipeId),
      });
    }
  };

  useEffect(() => {
    if (!isError && isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data: searchData, isLoading: isLoadingSearch } =
    useGetAlternativeRecipeSearchListQuery(
      {
        userId: userId,
        facilityId: facilityId,
        searchText: debouncedSearch,
      },
      {
        skip: !debouncedSearch,
        refetchOnMountOrArgChange: true,
      }
    );

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query.replace("&", "%26"));
      }, 400),
    []
  );

  const filterOptions = (options, { inputValue }) => {
    const searchValues = inputValue.split(" ");

    const filtered = options.filter((v) =>
      searchValues.every((searchValue) =>
        v.label.toLowerCase().includes(searchValue.toLowerCase())
      )
    );

    return filtered;
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, height: "auto" }}>
        <Button
          component={Link}
          color={"primary"}
          className="baseButton"
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={
            <span className="material-icons">keyboard_arrow_left</span>
          }
        >
          {"Back"}
        </Button>
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <FormProvider {...methods}>
            <Grid container spacing={1}>
              <Grid size={{ md: 5, xs: 12 }}>
                <BaseSelect
                  label="Diet"
                  name="dietId"
                  id="dietId"
                  control={control}
                  options={filteredDiets || []}
                  errors={errors}
                  loading={loading}
                />
              </Grid>
              <Grid offset={{ md: 7, xs: 0 }} />

              <Grid size={{ md: 5, xs: 12 }}>
                <BaseAutoComplete
                  formSelectProps={{
                    props: {
                      name: "alternateRecipeId",
                      id: "alternateRecipeId",
                      label: "Alternate Recipe	",
                    },
                    validationProps: {},
                  }}
                  control={control}
                  errors={errors}
                  options={searchData || []}
                  loading={isLoadingSearch}
                  defaultOptions={defaultRecipeType}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                />
              </Grid>

              <Grid offset={{ md: 7, xs: 0 }} />
              <Grid size={{ md: 5, xs: 12 }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={isEdit ? "Submit" : "Create"}
                  />
                </Box>
                {isError ? (
                  <Typography
                    sx={{
                      color: "error",
                    }}
                  >
                    There is already an alternate defined for this diet.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid size={3} />
            </Grid>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}

import {
  alpha,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseMultiSelect.md)
 */
export function BaseMultiSelect({
  control,
  setValue,
  errors,
  // Control and Errors and setValue should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  validationProps,
  options = [],
  variant = "outlined",
  ...rest
}) {
  const {
    setValue: setValueMethod,
    control: controlMethod,
    errors: formErrors,
  } = useSafeFormContext({ control, errors, setValue });

  const error = getNestedProperty(formErrors, rest.name);

  let OptionsArray = options;
  const handleChipDelete = (chipValue, selectedValues) => {
    const updatedValues = selectedValues.filter((value) => value !== chipValue);
    setValueMethod(rest.name, updatedValues);
  };
  const renderChip = (selected, selectedValues) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected?.map((value) => (
        <Chip
          key={value}
          size={"small"}
          label={options.find((x) => x.id === value)?.label}
          onDelete={() => handleChipDelete(value, selectedValues)}
          deleteIcon={
            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
          }
        />
      ))}
    </Box>
  );
  const renderOptions = (selectedValues) =>
    OptionsArray.filter((item) => !selectedValues.includes(item.id)).map(
      (value) => (
        <MenuItem disabled={value.disabled} value={value.id} key={value.id}>
          {value.label}
        </MenuItem>
      )
    );

  return (
    <FormControl fullWidth variant={variant || "outlined"}>
      {controlMethod ? (
        <>
          <Controller
            control={controlMethod}
            name={rest.name}
            rules={{ ...validationProps }}
            defaultValue={[]}
            render={({ field }) => {
              const selectedValues = field.value || [];
              return (
                <FormControl
                  // TODO: Cleanup
                  sx={(theme) => ({
                    "& .MuiFormLabel-root": {
                      color: theme.palette.black.main,
                      top: rest.size === "small" ? "0px" : "4px",
                      fontSize: theme.spacing(2.0),
                      "&.Mui-error": {
                        color: theme.palette.error.main,
                      },
                      "&.MuiInputLabel-shrink": {
                        top: "0px",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid black",
                      boxShadow: ` 0px 2px 4px 0px #00000040`,

                      "&.Mui-focused": {
                        boxShadow: `${alpha(
                          theme.palette[rest.color || "primary"].main,
                          0.25
                        )} 0 0 0 0.2rem`,
                        borderColor:
                          theme.palette[rest.color || "primary"].main,
                        transition: theme.transitions.create([
                          "border-color",
                          "background-color",
                          "box-shadow",
                        ]),
                      },
                    },

                    "& .MuiInputBase-root": {
                      padding:
                        rest.size === "small"
                          ? "2px 4px !important"
                          : "4px 6px !important",
                      boxShadow: ` 0px 2px 4px 0px #00000040`,
                      transition: theme.transitions.create([
                        "border-color",
                        "background-color",
                        "box-shadow",
                      ]),
                      "&.Mui-error": {
                        borderColor: theme.palette.error.main,
                        backgroundColor: alpha(theme.palette.error.main, 0.1),
                        boxShadow: `0px 2px 4px 0px ${alpha(
                          theme.palette.error.main,
                          0.2
                        )}`,
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding:
                        rest.size === "small"
                          ? "14px 4px !important"
                          : "14px 4px !important",
                    },
                  })}
                >
                  <InputLabel id="input-label">{rest.label}</InputLabel>
                  <Select
                    variant={variant || "outlined"}
                    labelId="input-label"
                    {...field}
                    multiple
                    size={rest.size}
                    defaultValue={[]}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label={rest.label}
                        error={!!error}
                      />
                    }
                    renderValue={(selected) =>
                      renderChip(selected, selectedValues)
                    }
                    MenuProps={MenuProps}
                  >
                    {renderOptions(selectedValues)}
                  </Select>
                </FormControl>
              );
            }}
          />
          <FormHelperText error={!!error}>
            {error?.message ?? ""}
          </FormHelperText>
        </>
      ) : (
        <Select
          {...rest}
          multiple
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => renderChip(selected, selected)}
        >
          {renderOptions()}
        </Select>
      )}
    </FormControl>
  );
}

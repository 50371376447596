import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Badge, CircularProgress } from "@mui/material";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCurrentUserQuery,
  useGetSystemMessagesByUserIdQuery,
} from "store/apis/UserApis";
import {
  setFacilityName,
  setVendorId,
  setVendorName,
} from "store/slices/userSlice/UserSlice";
import {
  selectIsModalShown,
  setFacilityId,
  setIsModalShown,
} from "store/slices/authSlice/AuthSlice";
import PersonMenu from "components/header/PersonMenu";
import UserSystemMessages from "../userSystemMessages/UserSystemMessages";
import styles from "./UserInformation.module.css";
import useMsalAccount from "utils/useMsalAccount";
import { InteractionStatus } from "@azure/msal-browser";

export default function UserInformation({ isBigScreen }) {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const dispatch = useDispatch();
  const { userId: currentUserId, account } = useMsalAccount();
  const isModalShown = useSelector(selectIsModalShown);
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const showLoader = inProgress !== InteractionStatus.None;

  const openSMModal = Boolean(anchorEl);

  const handleSMClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSMClose = () => {
    setAnchorEl(null);
    dispatch(setIsModalShown(true));
  };

  const {
    data: currentUserData,
    isFetching,
    isError,
    isSuccess,
    isUninitialized,
    refetch,
  } = useGetCurrentUserQuery(currentUserId, {
    skip: !isAuthenticated || currentUserId == null,
  });

  const {
    data: userSystemMessages,
    isSuccess: systemMessagesSuccess,
    refetch: refetchUser,
  } = useGetSystemMessagesByUserIdQuery(currentUserId, {
    skip: !isAuthenticated || currentUserId == null,
  });

  /*
  const {
    data: featureMealExtraData,
    isMealExtraError,
    isSuccess: isMealExtraSuccess,
  } = useGetUseFeatureQuery({key, label});
*/
  useEffect(() => {
    if (account && isAuthenticated && !isUninitialized) {
      refetch();
      refetchUser();
    }
  }, [account?.tenantId, isSuccess, isAuthenticated]);

  useEffect(() => {
    if (isSuccess && currentUserData?.facilityName && dispatch) {
      dispatch(setFacilityId(currentUserData.facilityId));
      dispatch(setFacilityName(currentUserData.facilityName));
      dispatch(setVendorName(currentUserData.vendorName));
      dispatch(setVendorId(currentUserData.vendorId));
    }
  }, [isSuccess, currentUserData, dispatch]);
  /*
    let key = "showMealExtraOnMealCard";
  let label = "Development";
  useEffect(() => {
    if (isMealExtraSuccess && featureMealExtraData) {
      dispatch(setMealExtra(featureMealExtraData));
    }
    else if (isMealExtraError) {
      dispatch(setMealExtra(false));
    }
  }, [featureMealExtraData]);
*/

  const userSystemMessagesLength = userSystemMessages?.length || 0;

  useEffect(() => {
    if (buttonRef?.current && userSystemMessagesLength > 0 && !isModalShown) {
      buttonRef.current.click();
    }
  }, [buttonRef?.current, userSystemMessagesLength, isModalShown]);

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "1.98rem",
        alignItems: "center",
        justifyContent: isBigScreen ? "end" : "center",
        width: "100%",
      }}
    >
      <UserSystemMessages
        open={openSMModal}
        close={handleSMClose}
        anchorEl={anchorEl}
      />

      {systemMessagesSuccess && userSystemMessagesLength > 0 && (
        <Badge
          color="error"
          overlap="circular"
          sx={{ marginRight: "8px" }}
          badgeContent={userSystemMessagesLength}
        >
          <Box
            onClick={handleSMClick}
            ref={buttonRef}
            sx={{
              paddingX: "12px",
              borderRadius: "50%",
              backgroundColor: "var(--yellowColor)",
              cursor: "pointer",
            }}
          >
            <Typography
              style={{ color: "white" }}
              sx={{
                fontSize: "2rem",
                color: "white",
              }}
            >
              <span className="material-icons">notifications</span>
            </Typography>
          </Box>
        </Badge>
      )}
      <Box className={styles.baseUserInfo}>
        {showLoader ? (
          <CircularProgress sx={{ marginLeft: 6 }} size={24} />
        ) : null}
        <AuthenticatedTemplate>
          {isFetching ? (
            <CircularProgress sx={{ marginLeft: 6 }} size={24} />
          ) : isSuccess && currentUserData ? (
            <>
              <Box
                className={styles.baseUserIcon}
                sx={{
                  marginX: "15px",
                  marginY: "4px",
                }}
              >
                {currentUserData.firstName.charAt(0) +
                  currentUserData.lastName.charAt(0)}
              </Box>
              <div className={styles.baseUserRight}>
                <div className={styles.baseUserName}>
                  {currentUserData.firstName + " " + currentUserData.lastName}
                </div>
                <div className={styles.baseUserCommunity}>
                  {currentUserData.facilityName}
                </div>
              </div>
            </>
          ) : isError ? (
            <Typography
              variant="p"
              sx={{
                paddingLeft: 3,
                color: "error",
              }}
            >
              Please Logout/Login
            </Typography>
          ) : null}
        </AuthenticatedTemplate>
        <PersonMenu userData={currentUserData} />
      </Box>
    </Box>
  );
}

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetRecipeMenuQuery } from "../../../../../store/apis/PlateFulRecipesApis";
import { Box } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";

export default function MenuUsage() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");

  const {
    data: menuUsage,
    isError: hasError,
    isLoading: loading,
  } = useGetRecipeMenuQuery({ recipeId: recipeId });

  const isError = hasError;
  const isLoading = loading;

  const menuUsageColumns = [
    {
      field: "menuName",
      headerName: "Menu",
      flex: 3,
    },
    {
      field: "day",
      headerName: "Day",
      flex: 1,
    },
    {
      field: "mealName",
      headerName: "meal",
      flex: 1,
    },
    {
      field: "seasonName",
      headerName: "season",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={menuUsage}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.menuName,
              apiPath: "deleteMenuRecipes",
              title: "Menu Usage",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1, height: "100%" }}>
        <BaseDataGrid
          height={`100%`}
          rows={menuUsage}
          columns={menuUsageColumns}
          getRowId={(row) => row.menuName + row.menuRecipeId}
          loading={isLoading}
          error={isError}
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
        />
      </Box>
    </>
  );
}

import React, { useState } from "react";
import ChatBody from "./ChatBody";
import ChatInput from "./ChatInput";
import { Button } from "@mui/material";
import { useAddChatMutation } from "../../store/apis/ChatApis";
import "./ChatAI.css";
import { DMStyles } from "styles/Styles";
import BaseContent from "components/shared/baseContent/BaseContent";
function ChatAIConvo() {
  const aiSessionId = sessionStorage.getItem("aiSessionId");
  const initialChatMessage = {
    sessionId: "initial-message",
    sender: "DMAI",
    message: "Hello! What can the Dining Manager chat help you find?",
    citations: [],
  };
  const [chatMessage, setChatMessage] = useState([initialChatMessage]);
  const [aiSubjectAreaId, setAiSubjectAreaId] = useState(1);

  const [addChatData] = useAddChatMutation();

  const sendChatMessage = async (message) => {
    setChatMessage((prev) => [...prev, message]);
    setAiSubjectAreaId(message.subjectAreaId || aiSubjectAreaId);
    const result = await addChatData(message);

    if (!result.error) {
      const data = result?.data;
      message = data.message;
      const citations = data.citations;
      let sessionId = "";
      if (aiSessionId == null || aiSessionId.length < 5) {
        sessionId = data.sessionId;
        sessionStorage.setItem("aiSessionId", data.sessionId);
      } else {
        sessionId = sessionStorage.getItem("aiSessionId");
      }
      setChatMessage((prev) => [
        ...prev,
        { sessionId: sessionId, sender: "DMAI", message, citations },
      ]);
      return;
    }
    message = "Sorry, I am having trouble understanding you.";

    if (result.error.status === 401) {
      message = "Sorry, you are not authorized to use this service.";
    }
    if (result.error.status === 403) {
      message = "Sorry, you are forbidden to use this service.";
    }
    setChatMessage((prev) => [
      ...prev,
      {
        sessionId: "error-message",
        sender: "DMAI",
        message,
        citations: [],
        bgColor: DMStyles.Red,
      },
    ]);
  };

  const resetChat = () => {
    setChatMessage([initialChatMessage]);
    setAiSubjectAreaId(1);
    sessionStorage.setItem("aiSessionId", "");
  };

  return (
    <BaseContent headerText="Chat RD" disableBack={true}>
      {/*header*/}
      <div className="page-body chatConvo">
        <div className="chatTitle">Welcome To The Dining Manager Chatbot</div>
        {/*body*/}
        <div className="chatBodyMain">
          <ChatBody
            chatMessage={chatMessage}
            sendChatMessage={sendChatMessage}
          />
        </div>
        <div style={{ margin: "16px 0px" }}>
          <Button
            sx={{ backgroundColor: DMStyles.Teal, color: "black" }}
            className="baseButton"
            color={"primary"}
            variant="contained"
            onClick={resetChat}
          >
            Reset Chat
          </Button>
        </div>
        {/*input*/}
        <ChatInput
          sendChatMessage={sendChatMessage}
          aiSubjectAreaId={aiSubjectAreaId}
        />
      </div>
    </BaseContent>
  );
}
export default ChatAIConvo;

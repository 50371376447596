import { Box, Typography } from "@mui/material";
import React from "react";
import { BaseButton } from "../shared/BaseButton";
import { tableSetupButtons, tableSideButtons } from "./constants";
import { useGetFacilityByIdQuery } from "../../store/apis/FacilityApis";
import "./TableSide.css";
import BaseContent from "../shared/baseContent/BaseContent";
import useMsalAccount from "utils/useMsalAccount";

export const TableSide = () => {
  const { facilityId, accountFromDB: currentUser } = useMsalAccount();
  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  const showTicketPrintingButton =
    !currentFacility?.tablesideTicketUseOrderPrinter;

  const showStartAMealButton = currentUser?.userRoles?.some((role) => ['Tableside_Setup_User', 'Admin', 'Menu_Team'].includes(role?.dmRoleName));

  return (
    <BaseContent
      headerText={`TableSide for ${
        currentFacility?.name ? currentFacility?.name : ""
      }`}
      disableBack={true}
    >
      <div className="box-div">
        <Box
          className="box-1"
          sx={{
            marginTop: "50px",
            marginLeft: "50px",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ marginLeft: "10px", fontWeight: "bold" }}
          >
            TableSide Ordering
          </Typography>
          <Box
            className="box-2"
            sx={{
              marginTop: "20px",
              marginLeft: "20px",
              display: "flex",
              flexWrap: "wrap",
              width: { md: "90%" },
              boxShadow: "var(--darkBoxShadow)",
              borderRadius: "10px",
              height: "200px",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {currentFacility && showStartAMealButton && (
              <BaseButton 
                text={"Start a Meal"}
                To={'/TableSide/KitchenSideSetup'}
                endIcon={"set_meal"}
              />
            )}
            {tableSideButtons?.map((data, index) => (
              <BaseButton
                sx={{
                  marginLeft: "0px !Important",
                }}
                key={index}
                text={data.text}
                endIcon={data.icon}
                To={data.Link}
                target={data.target}
              />
            ))}
            {currentFacility && showTicketPrintingButton && (
              <BaseButton
                text={"Ticket Printing"}
                To={`/TableSide/TicketPrinting?facilityId=${currentFacility?.id}`}
                endIcon={"print"}
              />
            )}
          </Box>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              marginLeft: "10px",
              marginTop: "40px",
              fontWeight: "bold",
            }}
          >
            Setup
          </Typography>
          <Box
            className="box-3"
            sx={{
              marginTop: "20px",
              marginLeft: "20px",
              marginBottom: "20px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              width: { md: "90%" },
              boxShadow: "var(--darkBoxShadow)",
              borderRadius: "10px",
              height: "200px",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {tableSetupButtons?.map((data, index) => (
              <BaseButton
                sx={{
                  marginLeft: "0px !Important",
                }}
                key={index}
                text={data.text}
                endIcon={data.icon}
                To={data.Link}
                target={data.target}
              />
            ))}
          </Box>
        </Box>
      </div>
    </BaseContent>
  );
};
